/* eslint-disable import/prefer-default-export */
export const QUERY_PARAMS = [
  'company',
  'role',
  'type',
  'difficulty',
  'page',
  'query',
  'stage',
  'sortBy',
  'filterBy',
];

export const DIFFICULTY_LEVELS = [
  {value: 'Easy', label: 'Easy'},
  {value: 'Medium', label: 'Medium'},
  {value: 'Hard', label: 'Hard'},
];
