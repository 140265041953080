import {useEffect} from 'react';

const useScrollToHash = (loading) => {
  useEffect(() => {
    if (!loading) {
      const {hash} = window.location;
      if (hash) {
        const sectionId = hash.substring(1); // Remove the '#' symbol
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({behavior: 'instant'});
        }
      }
    }
  }, [loading]);
};

export default useScrollToHash;
