import React from 'react';
import {Grid} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faLinkedinIn,
  faTiktok,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import Link from './Link';
import EmailSignUp from './EmailSignUp';
import {useAppContext} from '../hooks';
import {shouldHideCoaching} from '../util';

const Footer = () => {
  const {currentUser} = useAppContext();
  const hideCoaching = shouldHideCoaching(currentUser);

  return (
    <div>
      <Grid className="Footer">
        <div className="Footer__inner">
          <div className="Footer__section Footer__newsletter">
            <span className="Footer__logo">Exponent</span>
            <p>Get updates in your inbox with the latest tips, job listings, and more.</p>
            {!currentUser && (
              <EmailSignUp
                parent="Footer__newsletter"
                src="leads_footer"
              />
            )}
            <span className="Footer__section__heading mt-4">Follow Us</span>
            <Link className="Footer__section__social" alt="Subscribe to Exponent on Youtube" title="Subscribe to Exponent on Youtube" href="https://www.youtube.com/c/exponentTV?sub_confirmation=1">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link className="Footer__section__social" alt="Follow Exponent on LinkedIn" title="Follow Exponent on LinkedIn" href="https://www.linkedin.com/company/tryexponent/">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Link>
            <Link className="Footer__section__social" alt="Follow Exponent on TikTok" title="Follow Exponent on TikTok" href="https://www.tiktok.com/@tryexponent">
              <FontAwesomeIcon icon={faTiktok} />
            </Link>
            <Link className="Footer__section__social" alt="Follow Exponent on Instagram" title="Follow Exponent on Instagram" href="https://www.instagram.com/tryexponent/">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link className="Footer__section__social" alt="Like Exponent on Facebook" title="Like Exponent on Facebook" href="https://www.facebook.com/tryexponent">
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
            <Link className="Footer__section__social" alt="Follow Exponent on Twitter" title="Follow Exponent on Twitter" href="https://twitter.com/tryexponent">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
          </div>
          <div className="Footer__section">
            <span className="Footer__section__heading">Products</span>
            <Link href="/upgrade?src=footer">Pricing</Link>
            <Link href="/courses?src=footer">Courses</Link>
            {!hideCoaching && <Link href="/coaching?src=footer">Coaching</Link>}
            <Link href="/questions?src=footer">Interview Questions</Link>
            <Link href="/practice?src=footer">Peer Mock Interviews</Link>
            <Link href="/jobs/referrals?src=footer">Job Referrals</Link>
            <Link href="/companies?src=footer">All Companies</Link>
          </div>
          <div className="Footer__section">
            <span className="Footer__section__heading">Courses</span>
            <Link href="/courses/pm?src=footer">Product Management</Link>
            <Link href="/courses/software-engineering?src=footer">Software Engineering</Link>
            <Link href="/courses/data-engineering?src=footer">Data Engineer</Link>
            <Link href="/courses/system-design-interviews?src=footer">System Design</Link>
            <Link href="/courses/engineering-management?src=footer">Engineering Management</Link>
            <Link href="/courses/tpm?src=footer">Technical Program Management</Link>
            <Link href="/courses/product-designer-interview?src=footer">UX / Product Design</Link>
            <Link href="/courses/data-science?src=footer">Data Science</Link>
            <Link href="/courses/sql-interviews?src=footer">SQL Interviews</Link>
            <Link href="/courses/solutions-architect?src=footer">Solutions Architect</Link>
            <Link href="/courses/bizops?src=footer">BizOps & Strategy</Link>
            <Link href="/courses/ml-engineer?src=footer">Machine Learning</Link>
          </div>
          <div className="Footer__section">
            <span className="Footer__section__heading">Interview Questions</span>
            <Link href="/questions?role=pm&src=footer">PM Interview Questions</Link>
            <Link href="/questions?role=tpm&src=footer">TPM Interview Questions</Link>
            <Link href="/questions?role=em&src=footer">EM Interview Questions</Link>
            <Link href="/questions?role=swe&src=footer">Software Engineering Interview Questions</Link>
            <Link href="/questions?role=data-engineer&src=footer">Data Engineering Interview Questions</Link>
            <Link href="/questions?role=solutions-architect&src=footer">Solutions Architect Interview Questions</Link>
            <Link href="/questions?role=bizops&src=footer">BizOps Interview Questions</Link>
            <Link href="/questions?role=design&src=footer">Designer Interview Questions</Link>
            <Link href="/questions?role=ml-engineer&src=footer">Machine Learning Interview Questions</Link>
            <Link href="/questions?role=data-science&src=footer">Data Science Interview Questions</Link>
          </div>
        </div>
      </Grid>
      <Grid className="Footer pt-0">
        <div className="Footer__inner">
          <div className="Footer__section">
            <span className="Footer__section__heading">Popular articles</span>
            <Link href="/blog/system-design-interview-guide?src=footer">Ace the System Design Interview</Link>
            <Link href="/blog/top-product-manager-interview-questions/?src=footer">Top Product Manager Interview Questions</Link>
            <Link href="/blog/how-to-nail-amazons-behavioral-interview-questions/?src=footer">Amazon Behavioral Interview Questions</Link>
            <Link href="/blog/best-tech-companies-to-work-for/?src=footer">Best Tech Companies To Work For</Link>
            <Link href="/blog/how-to-prepare-for-an-engineering-manager-interview?src=footer">Ace the Engineering Manager Interview</Link>
            <Link href="/blog?src=footer">Exponent Blog</Link>
          </div>
          <div className="Footer__section">
            <span className="Footer__section__heading">Guides</span>
            <Link href="/guides/google-pm-interview?src=footer">Google PM Interviews</Link>
            <Link href="/guides/amazon-solutions-architect-interview?src=footer">AWS Solutions Architect Interviews</Link>
            <Link href="/guides/amazon-pm-interview?src=footer">Amazon PM Interviews</Link>
            <Link href="/guides/google-em-interview?src=footer">Google EM Interviews</Link>
            <Link href="/guides/amazon-sdm-interview?src=footer">Amazon SDM Interviews</Link>
            <Link href="/guides/microsoft-pm-interview?src=footer">Microsoft PM Interviews</Link>
          </div>
          {!hideCoaching && (
            <div className="Footer__section">
              <span className="Footer__section__heading">Coaching</span>
              <Link href="/coaching?src=footer">1:1 Coaching</Link>
              <Link href="/coaching?category=mock_interviews&src=footer">Mock Interviews</Link>
              <Link href="/resume?src=footer">Resume Review</Link>
              <Link href="/coaching?category=career_coaching&src=footer">Career Coaching</Link>
              <Link href="/coaching?category=negotiation&src=footer">Negotiation</Link>
            </div>
          )}
          <div className="Footer__section">
            <span className="Footer__section__heading">For Partners</span>
            <Link href="/affiliate?src=footer">Affiliate Program</Link>
            <Link href="https://www.tryexponent.com/collaborate">Work With Us</Link>
            <Link href="https://www.tryexponent.com/pages/university">For Universities</Link>
            <Link href="https://www.tryexponent.com/pages/business">For Businesses</Link>
          </div>
          <div className="Footer__section">
            <span className="Footer__section__heading">Company</span>
            <Link href="https://www.tryexponent.com/collaborate">Work With Us</Link>
            <Link href="/about">About Exponent</Link>
            <Link href="/support?src=footer">Support and FAQ</Link>
            <Link href="/reviews?src=footer">Reviews</Link>
            <Link href="/blog?src=footer">Exponent Blog</Link>
          </div>
        </div>
        <div className="copyright">
          <div>
            Exponent &copy;
            {(new Date()).getFullYear()}
          </div>
          <div>
            <Link href="/tos">Terms of Service</Link>
            {' | '}
            <Link href="/privacy">Privacy</Link>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export const MinimalFooter = () => (
  <Grid className="Footer">
    <div className="copyright flex justify-between">
      <div>
        Exponent &copy;
        {(new Date()).getFullYear()}
      </div>
      <div>
        <Link href="/tos">Terms of Use</Link>
        {' • '}
        <Link href="/support">Support</Link>
      </div>
    </div>
  </Grid>
);

export default Footer;
