import React from 'react';
import {
  ChatAlt2Icon,
  ClipboardCheckIcon,
  CodeIcon,
  NewspaperIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/outline';

import {
  MapIcon,
  GroupIcon,
  PencilIcon,
  InformationIcon,
  DotsIcon,
  BriefcaseIcon,
  GiftIcon,
  DocumentIcon,
  AcademicCapIcon,
  ChipNavIcon,
  ClipboardNavIcon,
  PuzzleNavIcon,
  DataNavIcon,
  ShortRightArrowIcon,
  SpeechBubbleIcon,
} from '../components/svg';
import {shouldHideCoaching} from '../util';

/**
 * Menu is the source of truth for DesktopNavigation and MobileNavigation. It
 * consists of two top level keys, `primaryItems` and `accountItems`.
 *
 * `primaryItems` live on the left hand side of DesktopNavigation and at the
 * top of the expanded MobileNavigation. They have the following fields
 * available:
 *
 *   title - Text to be displayed.
 *   link - Relative URL to be client-side routed to.
 *   children - Optional React element to use instead of title on DesktopNavigation.
 *   activePaths - Array of relative URLs. Highlight if react-router matches.
 *   forceAnchor - Use a native anchor element instead of react-router <Link>.
 *   items - Displayed in dropdown. Array of objects that must contain:
 *      icon - Uninstantiated SVG react component.
 *      title - Text to be displayed.
 *      description - Text beneath title.
 *      link - Relative URL to be client-side routed to.
 *      forceAnchor - Use a native anchor element instead of react-router <Link>.
 *      badge - Optional text to be displayed in a badge.
 *   gridItems - Optionally displayed beneath items. Object that can contain:
 *       title - Text to be displayed above gridItems items.
 *       subtitle - CTA displayed beneath gridItems items.
 *       link - Relative URL that subtitle CTA client-side routes to.
 *       items - Array of objects containing titles, links, forceAnchor, badge.
 *
 * `accountItems` live inside of the user avatar dropdown of DesktopNavigation
 * and at the bottom of the expanded MobileNavigation. They have the following
 * fields available:
 *
 *   title - Text to be displayed.
 *   link - Relative URL to be client-side routed to.
 *
 * Top level items of `primaryItems` and `accountItems` also have the following
 * fields available to control where a particular item is displayed or
 * to force the use of native anchor element:
 *
 *   onlyDesktop - Boolean.
 *   onlyMobile - Boolean.
 *   shouldHide - Function implementing custom display logic. Takes appContext
 *                and returns a boolean.
 */
const Menu = {
  primaryItems: [
    {
      title: 'Courses',
      link: '/courses?src=nav',
      activePaths: [
        '/courses',
        '/courses/[courseOrTrack]',
        '/courses/[courseOrTrack]/[courseOrLesson]',
        '/courses/[courseOrTrack]/[courseOrLesson]/[lesson]',
      ],
      itemsTitle: 'More Courses',
      items: [
        {title: 'Solutions Architect', link: '/courses/solution-architect-interview?src=nav'},
        {title: 'UX / Product Design', link: '/courses/product-designer-interview?src=nav'},
        {title: 'Get More Interviews', link: '/courses/get-the-interview?src=nav'},
        {title: 'BizOps & Strategy', link: '/courses/bizops?src=nav'},
        {title: 'Negotiation', link: '/courses/tech-salary-offer-negotiation?src=nav'},
        {title: 'Behavioral', link: '/courses/behavioral?src=nav'},
        {title: 'PM Skills', link: '/courses/pm-skills?src=nav'},
        {title: 'SQL Interviews', link: '/courses/sql-interviews?src=nav'},
      ],
      gridItemsTitle: 'Interview Courses',
      gridItems: [
        {
          icon: ClipboardNavIcon,
          title: 'Product Management',
          link: '/courses/pm?src=nav',
          description: 'Ace product interviews from strategy cases to technical skills.',
        },
        {
          icon: GroupIcon,
          title: 'Engineering Management',
          link: '/courses/engineering-management?src=nav',
          description: 'Review key leadership and people management skills.',
        },
        {
          icon: CodeIcon,
          title: 'Software Engineering',
          link: '/courses/software-engineering?src=nav',
          description: 'Learn essential strategies for coding problems and more.',
        },
        {
          icon: PuzzleNavIcon,
          title: 'System Design',
          link: '/courses/system-design-interviews?src=nav',
          description: 'Define architectures, interfaces, and databases in a time crunch.',
        },
        {
          icon: PresentationChartBarIcon,
          title: 'Data Science',
          link: '/courses/data-science?src=nav',
          description: 'Execute statistical techniques and experimentation effectively.',
          badge: 'New',
        },
        {
          icon: ChipNavIcon,
          title: 'Machine Learning',
          link: '/courses/ml-engineer?src=nav',
          description: 'Review building, evaluating, and deploying AI/ML models.',
        },
        {
          icon: DataNavIcon,
          title: 'Data Engineering',
          link: '/courses/data-engineering?src=nav',
          description: 'Design complex data models and ETL pipelines.',
          badge: 'New',
        },
        {
          icon: SpeechBubbleIcon,
          title: 'TPM',
          link: '/courses/tpm?src=nav',
          description: 'Demonstrate program sense and cross-functional experience.',
        },
      ],
      cta: {
        title: (
          <span className="text-indigo items-center flex">
            View all courses
            <ShortRightArrowIcon className="ml-2" />
          </span>
        ),
        link: '/courses?src=nav',
      },
    },
    {
      title: 'Questions',
      activePaths: ['/questions', '/questions/[id]/[slug]'],
      link: '/questions?src=nav',
      gridItemsTitle: 'Top Questions',
      gridItems: [
        {
          icon: ClipboardNavIcon,
          title: 'Product Management',
          link: '/questions?role=pm&src=nav',
        },
        {
          icon: ChatAlt2Icon,
          title: 'Behavioral Questions',
          link: '/questions?type=behavioral&src=nav',
        },
        {
          icon: CodeIcon,
          title: 'Coding Questions',
          link: '/questions?filterBy=codeEditor&src=nav',
        },
        {
          icon: PuzzleNavIcon,
          title: 'System Design',
          link: '/questions?type=system-design&src=nav',
        },
        {
          icon: DataNavIcon,
          title: 'SQL Questions',
          link: '/questions?type=sql&src=nav',
        },
        {
          icon: ChipNavIcon,
          title: 'Machine Learning',
          link: '/questions?role=ml-engineer&src=nav',
        },
        {
          icon: PresentationChartBarIcon,
          title: 'Data Science',
          link: '/questions?role=data-science&src=nav',
        },
        {
          icon: DataNavIcon,
          title: 'Data Engineering',
          link: '/questions?role=data-engineer&src=nav',
        },
      ],
      cta: {
        title: (
          <span className="text-indigo items-center flex">
            Browse all questions
            <ShortRightArrowIcon className="ml-2" />
          </span>
        ),
        link: '/questions?src=nav',
      },
    },
    {
      title: 'Peer Mocks',
      activePaths: ['/practice'],
      link: '/practice?src=nav',
    },
    {
      title: 'Coaching',
      activePaths: [
        '/coaching',
        '/coaching/start',
        '/coaching/[skill]',
        '/coach/[coach]',
        '/coaching/booking',
      ],
      link: '/coaching/start?src=nav',
      align: 'right',
      items: [
        {
          icon: ClipboardCheckIcon,
          title: 'Mock Interviews & Coaching',
          description: 'Practice with our team of senior tech coaches.',
          link: '/coaching?category=mock_interviews&src=nav',
        },
        {
          icon: DocumentIcon,
          title: 'Resume Review',
          description: 'Get your resume reviewed by a senior tech recruiter.',
          link: '/resume?src=nav',
        },
        {
          icon: ChatAlt2Icon,
          title: 'Salary Negotiation',
          description: 'Increase your offer with our expert negotiators.',
          link: '/coaching?category=negotiation&src=nav',
        },
      ],
      shouldHide: ({currentUser}) => shouldHideCoaching(currentUser),
    },
    {
      title: 'Partners',
      activePaths: [],
      shouldHide: ({currentUser}) => currentUser,
      align: 'right',
      items: [
        {
          icon: AcademicCapIcon,
          title: 'For universities',
          description: 'Give your students tech interview prep.',
          link: '/pages/university?src=nav',
        },
        {
          icon: BriefcaseIcon,
          title: 'For businesses',
          description: 'Improve your placement rates, outcomes, and more.',
          link: '/pages/business?src=nav',
        },
        {
          icon: GroupIcon,
          title: 'Affiliate program',
          description: 'Recommend us to others and earn commission.',
          link: '/affiliate?src=nav',
        },
        {
          icon: InformationIcon,
          title: 'Work with us',
          description: 'Help us grow the Exponent community.',
          link: '/collaborate?src=nav',
        },
      ],
    },
    {
      title: 'Pricing',
      activePaths: ['/upgrade'],
      link: '/upgrade?src=nav',
      shouldHide: ({hasSubscription}) => hasSubscription,
    },
    {
      title: 'Coach Dashboard',
      activePaths: [
        '/coaching/dashboard',
        '/coaching/submit',
        '/coaching/submit/complete',
        '/coaching/submit/cancel',
        '/coaching/submit/resume',
      ],
      link: '/coaching/dashboard?src=nav',
      shouldHide: ({coachSlug}) => !coachSlug,
    },
    {
      title: 'More',
      children: <DotsIcon />,
      hideCaret: true,
      align: 'right',
      items: [
        {
          icon: BriefcaseIcon,
          title: 'Job Referrals',
          description: 'Get job referrals to top tech companies.',
          link: '/jobs/referrals?src=nav',
        },
        {
          icon: MapIcon,
          title: 'Company Guides',
          description: 'Get an inside look at top companies’ interview processes.',
          link: '/guides?src=nav',
        },
        {
          icon: PencilIcon,
          title: 'Blog',
          description: 'Check out our blog on tech interviewing tips, strategies, and more.',
          link: '/blog?src=nav',
        },
        {
          icon: NewspaperIcon,
          title: 'Resources',
          description: 'Members-only articles, videos, and interviews.',
          link: '/resources?src=nav',
        },
        {
          icon: InformationIcon,
          title: 'Work with us',
          description: 'Help us grow the Exponent community.',
          link: '/collaborate?src=nav',
        },
        {
          icon: GiftIcon,
          title: 'Perks',
          description: 'Access exclusive member benefits.',
          link: '/perks?src=nav',
        },
      ],
    },
  ],
  accountItems: [
    {title: 'Profile', link: '/account/profile?src=nav', onlyDesktop: true},
    {title: 'Settings', link: '/account?src=nav'},
    {title: 'Refer friends', link: '/referrals?src=nav'},
    {title: 'Contact us', link: '/support'},
    {title: 'Log out', link: '/logout?src=nav'},
  ],
};

export default Menu;
